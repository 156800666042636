<template>
  <nav>
      <ul class="nav navbar-nav align-items-start">
        <li class="nav-item">
            <a class="nav-link" href="#"></a>
        </li>
        <li class="text-light nav-item">
            <img src="~@/assets/rec_flag.png"/>
            <h3 class="navbar-text">為孩子帶來不一樣的學習體驗</h3>
        </li>  

    </ul>
    
  </nav>
<div class="container mt-4">
  <div class="container text-left">
    <h2>{{studentName}}</h2>
    <div class="pt-2"><h5>語言範例精選</h5><span v-for="word in words.concat(longestWords)" v-bind:key="word" class="word-badge badge text-left badge-secondary mt-3">{{word}}</span>
    </div>
    <div class="container mt-2">
    <!-- 
    <h5>AI語言分析</h5>
    <div class="row">
      <div class="col-sm-3" v-for="entity in nlpEntities" v-bind:key="entity">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">{{entity.name}}</h5>
            <p class="card-text">Salience: {{entity.salience}}</p>
            <a href="#" class="btn btn-primary">{{entity.type}}</a>
          </div>
        </div>
      </div>
    </div>
    -->
    </div>
   </div>
   <div class="mt-3">
    <div class="container text-left">
      <div>
        <h4>課堂導師{{teacherName}}</h4>
        <div class="row">
            <div class="col-sm-2">
                <img  v-bind:src="teacherImage" class="img-fluid rounded-circle" alt="Teacher Image">
            </div>
            <div class="col-sm-10">
                <h6>整體評語</h6>
                            <button class="btn btn-primary" v-if="!audioPlaying" v-on:click="playAudio(audioUrl)">
                <play-icon />
                </button>
                <button class="btn btn-primary" v-if="audioPlaying" v-on:click="pauseAudio()">
                <pause-icon />
                </button>

                <p class="mt-4">{{teacherComments}}
                </p>


            </div>
        </div>
      </div>
   </div>  
  </div>
  <div v-if="sessionLanguage == '普通話' || sessionLanguage == '廣東話'" class="container text-left mt-5">
    <h4>
        <span class="big-lightbulb"><lightbulb-on-outline-icon />
        </span>
        根據語言表現分析，以下是個人化學習建議：
    </h4>
  </div>
</div>
<div v-if="manualLevelPrediction"  class="container-fluid classes col-sm-8 col-xs-12">
  <div class="card main-card">
    <img :src="'/img/Course' + manualLevelPrediction + '_forced recommendation.jpg'" class="card-img-top" alt="...">
  </div>
  <div class="mt-2 mb-5">
    
      <a v-if="manualLevelPrediction < 5" type="button" class="btn btn-lg btn-primary" href="https://ispeech.community/%e3%80%90%e7%b7%9a%e4%b8%8a%e3%80%91%e5%bf%ab%e6%a8%82%e8%aa%aa%e5%8f%a5%e5%ad%90%e8%aa%b2%e7%a8%8b/" role="btn">報讀課程</a> 
      <a v-if="manualLevelPrediction > 4 && manualLevelPrediction < 7" type="button" class="btn btn-lg btn-primary" href="https://ispeech.community/%e3%80%90%e7%b7%9a%e4%b8%8a%e3%80%91%e6%95%85%e4%ba%8b%e6%bc%94%e8%aa%aa%e8%aa%b2%e7%a8%8b/" role="btn">報讀課程</a>
      <a v-if="manualLevelPrediction > 6" type="button" class="btn btn-lg btn-primary" href="https://ispeech.community/%e3%80%90%e7%b7%9a%e4%b8%8a%e3%80%91%e9%ab%98%e9%9a%8e%e6%80%9d%e7%b6%ad%e6%bc%94%e8%aa%aa%e8%aa%b2%e7%a8%8b/" role="btn">報讀課程</a>
  </div>
</div>
<div v-if="!manualLevelPrediction && sessionLanguage == '英語'" class="container-fluid classes col-sm-8 col-xs-12">
  <div class="card main-card">
    <img src="/img/Eng_signature.jpg" class="card-img-top" alt="...">
    <div class="card-body main-card-body">
      <div class="flex-sm-right">
      </div>
    </div>
  </div>
  <div class="mt-2 mb-5">
    <a type="button" class="btn btn-lg btn-primary" href="https://ispeech.community/%E3%80%90%E7%B7%9A%E4%B8%8A%E3%80%91%E8%8B%B1%E6%96%87%E6%95%85%E4%BA%8B%E6%95%99%E5%AD%B8%E8%AA%B2%E7%A8%8B/" role="btn">報讀課程</a> 
  </div>
      <div v-if="classes.length" class="container">
        以下的課程可能更適合您的孩子：
    </div>
    <div class="card flex-sm-row">
      <img class="d-sm-none card-img" src="/img/Eng_1_interactive storytelling.jpg"/>
      <img class="d-none d-sm-inline card-img-sm-left" src="/img/Eng_1_interactive storytelling.jpg"/>
      <div class="card-body">
        <div class="row ml-3">
          <h3 class="card-title">
            Interactive Storytelling
          </h3>
        </div>
        <hr>
        <div class="container">
          <div class="container text-left">
                學習焦點:
          </div>
          <div class="container mb-5">
            <span class="focus-point-badge float-left badge badge-secondary">
              <span class="big-lightbulb">
                <check-outline-icon size="14" />
              </span>
              Enrich Language Environment
            </span>
            <span class="focus-point-badge float-left badge badge-secondary">
              <span class="big-lightbulb">
                <check-outline-icon size="14" />
              </span>
               Cultivate An Interest In Reading
            </span>
            <span class="focus-point-badge float-left badge badge-secondary">
              <span class="big-lightbulb">
                <check-outline-icon size="14" />
              </span>
              Broaden Vocabulary Range
            </span>
            <span class="focus-point-badge float-left badge badge-secondary">
              <span class="big-lightbulb">
                <check-outline-icon size="14" />
              </span>
              Improve Sentence Complexity
            </span>
            <span class="focus-point-badge float-left badge badge-secondary">
              <span class="big-lightbulb">
                <check-outline-icon size="14" />
              </span>
              Enhance Motivation For Verbal Communication
            </span>
        </div>
        <div class="container mb-5">
            <span class="focus-point-badge float-left badge badge-secondary">
              <span class="big-lightbulb">
                <check-outline-icon size="14" />
              </span>
              Strengthen Expression Skills
            </span>
        </div> 
        </div> 
      </div>
           <div class="card-body row">

        <a type="button" class="btn btn-md register-btn btn-primary" href="https://ispeech.community/%E3%80%90%E7%B7%9A%E4%B8%8A%E3%80%91%E8%8B%B1%E6%96%87%E6%95%85%E4%BA%8B%E6%95%99%E5%AD%B8%E8%AA%B2%E7%A8%8B/" role="btn">報讀課程</a>  

      </div>
    </div>
    <div class="card flex-sm-row">
      <img class="d-sm-none card-img" src="/img/Eng_2_ORT reading programme.jpg"/>
      <img class="d-none d-sm-inline card-img-sm-left" src="/img/Eng_2_ORT reading programme.png"/>
      <div class="card-body">
        <div class="row ml-3">
          <h3 class="card-title">
            ORT Reading Programme
          </h3>
        </div>
        <hr>
        <div class="container">
          <div class="container text-left">
                學習焦點:
          </div>
          <div class="container mb-5">
            <span class="focus-point-badge float-left badge badge-secondary">
              <span class="big-lightbulb">
                <check-outline-icon size="14" />
              </span>
              Nurture Interest In English Reading
            </span>
            <span class="focus-point-badge float-left badge badge-secondary">
              <span class="big-lightbulb">
                <check-outline-icon size="14" />
              </span>
               Develop Conversational English Skills
            </span>
            <span class="focus-point-badge float-left badge badge-secondary">
              <span class="big-lightbulb">
                <check-outline-icon size="14" />
              </span>
              Expand English Vocabulary

            </span>
            <span class="focus-point-badge float-left badge badge-secondary">
              <span class="big-lightbulb">
                <check-outline-icon size="14" />
              </span>
              Acquire Basic English Syntax
            </span>
            <span class="focus-point-badge float-left badge badge-secondary">
              <span class="big-lightbulb">
                <check-outline-icon size="14" />
              </span>
              Accumulate Rich English Reading Experience
            </span>
        </div>
        </div> 
      </div>
           <div class="card-body row">

        <a type="button" class="btn btn-md register-btn btn-primary" href="https://ispeech.community/%E3%80%90%E7%B7%9A%E4%B8%8A%E3%80%91%E8%8B%B1%E6%96%87%E6%95%85%E4%BA%8B%E6%95%99%E5%AD%B8%E8%AA%B2%E7%A8%8B/" role="btn">報讀課程</a>  

      </div>
    </div>
    <div class="card flex-sm-row">
      <img class="d-sm-none card-img" src="/img/Eng_3_high order thinking and communication.jpg"/>
      <img class="d-none d-sm-inline card-img-sm-left" src="/img/Eng_3_high order thinking and communication.jpg"/>
      <div class="card-body">
        <div class="row ml-3">
          <h3 class="card-title">
            High Order Thinking & Communication
          </h3>
        </div>
        <hr>
        <div class="container">
          <div class="container text-left">
                學習焦點:
          </div>
          <div class="container mb-5">
            <span class="focus-point-badge float-left badge badge-secondary">
              <span class="big-lightbulb">
                <check-outline-icon size="14" />
              </span>
               Develop Advanced Critical Thinking Skills
            </span>
            <span class="focus-point-badge float-left badge badge-secondary">
              <span class="big-lightbulb">
                <check-outline-icon size="14" />
              </span>
               Improve Effective Communication Strategies
            </span>
            <span class="focus-point-badge float-left badge badge-secondary">
              <span class="big-lightbulb">
                <check-outline-icon size="14" />
              </span>
              Foster Creative Brainstorming Techniques
            </span>
            <span class="focus-point-badge float-left badge badge-secondary">
              <span class="big-lightbulb">
                <check-outline-icon size="14" />
              </span>
              Strengthen Ability To Articulate And Defend Ideas
            </span>
            <span class="focus-point-badge float-left badge badge-secondary">
              <span class="big-lightbulb">
                <check-outline-icon size="14" />
              </span>
              Cultivate Active Listening Skills
            </span>
        </div>
        </div> 
      </div>
           <div class="card-body row">
        <a type="button" class="btn btn-md register-btn btn-primary" href="https://ispeech.community/%E3%80%90%E7%B7%9A%E4%B8%8A%E3%80%91%E8%8B%B1%E6%96%87%E6%95%85%E4%BA%8B%E6%95%99%E5%AD%B8%E8%AA%B2%E7%A8%8B/" role="btn">報讀課程</a>  

      </div>
    </div>
    <div class="card flex-sm-row">
      <img class="d-sm-none card-img" src="/img/Eng_4_English Language Arts.png"/>
      <img class="d-none d-sm-inline card-img-sm-left" src="/img/Eng_4_English Language Arts.png"/>
      <div class="card-body">
        <div class="row ml-3">
          <h3 class="card-title">
            English Language Arts
          </h3>
        </div>
        <hr>
        <div class="container">
          <div class="container text-left">
                學習焦點:
          </div>
          <div class="container mb-5">
            <span class="focus-point-badge float-left badge badge-secondary">
              <span class="big-lightbulb">
                <check-outline-icon size="14" />
              </span>
                Mastery of Language Nuances
            </span>
            <span class="focus-point-badge float-left badge badge-secondary">
              <span class="big-lightbulb">
                <check-outline-icon size="14" />
              </span>
               Analytical And Interpretive Skills
            </span>
            <span class="focus-point-badge float-left badge badge-secondary">
              <span class="big-lightbulb">
                <check-outline-icon size="14" />
              </span>
              Creative Language Use
            </span>
            <span class="focus-point-badge float-left badge badge-secondary">
              <span class="big-lightbulb">
                <check-outline-icon size="14" />
              </span>
              Effective And Persuasive Communication
            </span>
            <span class="focus-point-badge float-left badge badge-secondary">
              <span class="big-lightbulb">
                <check-outline-icon size="14" />
              </span>
              Cultural And Linguistic Appreciation
            </span>
        </div>
        </div> 
      </div>
           <div class="card-body row">
        <a type="button" class="btn btn-md register-btn btn-primary" href="https://ispeech.community/%E3%80%90%E7%B7%9A%E4%B8%8A%E3%80%91%E8%8B%B1%E6%96%87%E6%95%85%E4%BA%8B%E6%95%99%E5%AD%B8%E8%AA%B2%E7%A8%8B/" role="btn">報讀課程</a>  

      </div>
    </div>

</div>
<div v-if="!manualLevelPrediction && (sessionLanguage == '普通話' || sessionLanguage == '廣東話')" class="container-fluid classes col-sm-8 col-xs-12">
  <div class="card main-card">
    <img src="/img/Course_5.jpg" class="card-img-top" alt="...">
      <div class="card-body main-card-body">
          <div class="flex-sm-right">
            <div class="progress" v-bind:data-percentage="maxConfidenceOf5And6">
              <span class="progress-left">
                <span class="progress-bar"></span>
              </span>
              <span class="progress-right">
                <span class="progress-bar"></span>
              </span>
              <div class="progress-value">
                <div>
                  {{maxConfidenceOf5And6}}%<br/>
                  <span>Suitable</span>
                </div>
              </div>
              </div>
          </div>
      </div>
    </div>
    <div class="mt-2 mb-5">
    
      <a type="button" class="btn btn-lg btn-primary" href="https://ispeech.community/%e3%80%90%e7%b7%9a%e4%b8%8a%e3%80%91%e6%95%85%e4%ba%8b%e6%bc%94%e8%aa%aa%e8%aa%b2%e7%a8%8b/" role="btn">報讀課程</a> 
    </div>
    <div v-if="classes.length" class="container">
        以下的課程可能更適合您的孩子：
    </div>
    <div v-for="{ name, courseId, focusPoint } in classes" v-bind:key="courseId" class="card flex-sm-row">
      <img class="d-sm-none card-img" v-bind:src="'/img/Course_' + courseId + '.jpg'"/>

      <img class="d-none d-sm-inline card-img-sm-left" v-bind:src="'/img/Course_' + courseId + '.jpg'"/>

      <div class="card-body">
        <div class="row ml-3">
          <h3 class="card-title">
            {{name}}
          </h3>
          <p class="float-left ml-2">課程</p>
        </div>
        <hr>
        <div class="container">
          <div class="container text-left">
                學習焦點:
          </div>
          <div class="container mb-5"><span v-for="point in focusPoint" v-bind:key="point" class="focus-point-badge float-left badge badge-secondary"> <span class="big-lightbulb"><check-outline-icon size="14" />
        </span>
{{point}}</span></div>
        </div>  

      </div>
           <div class="card-body row">

        <a type="button" class="btn btn-md register-btn btn-primary" href="https://ispeech.community/%e3%80%90%e7%b7%9a%e4%b8%8a%e3%80%91%e5%bf%ab%e6%a8%82%e8%aa%aa%e5%8f%a5%e5%ad%90%e8%aa%b2%e7%a8%8b/" role="btn">報讀課程</a>  

      </div>

    </div>
    <div v-if="otherInfo"  class="container col-7 mb-3 mt-5">
        <h6>其他資料</h6>
        <p class="mt-4 text-left">{{otherInfo}}</p>
    </div>
</div>
<!-- Footer -->
<footer class="page-footer font-small blue">

  <!-- Copyright -->
  <div class="footer-copyright text-center py-3">Powered by iSpeech AI Speech & Language Technology © 2023
  </div>
  <!-- Copyright -->

</footer>
<!-- Footer -->

</template>
<style>
.big-lightbulb {
    font-size: 1.4em;
}
.focus-point-badge {
  margin: 0.2em;
  background-color: #c3d4e4;
  color: #212529;
  font-size: 0.9em;
   padding: 0.5em;
   border-radius: 2px;
}
.word-badge {
  background-color: #c3d4e4;
  white-space: normal;
  color: #212529;
  font-size: 1.2em;
  line-height: 1.5rem;
   padding: 0.6em;
   margin-left: 0.5em;
    border-radius: 2px;
}
.register-btn {
    position: absolute; 
    bottom: 10px;
    right: 10px;
}
.btn-primary {
    background-color: #42c2d5;
    border-color: #42c2d5;
}
</style>

<script>
import { computed, reactive } from 'vue';
import CheckOutlineIcon from 'vue-material-design-icons/CheckOutline.vue';
import LightbulbOnOutlineIcon from 'vue-material-design-icons/LightbulbOnOutline.vue';
import PlayIcon from 'vue-material-design-icons/Play.vue';
import PauseIcon from 'vue-material-design-icons/Pause.vue';
import { useRoute } from 'vue-router';
import { db, storage } from '@/firebase';
import { getDoc, getDocs, collection, query, doc, where } from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage';
import storageHelper from 'storage-helper';

export default {
components: {
    CheckOutlineIcon,
    LightbulbOnOutlineIcon,
    PlayIcon,
    PauseIcon
},
 data() { 
   return {
     classes: [],
     maxConfidenceOf5And6: 0,
     words: [],
     longestWords: [],
     userName: "",
     emailAdress: "",
     manualLevelPrediction: "",
     sessionLanguage: "",
     studentName: "",
     teacherName: "",
     teacherImage: "",
     audioUrl: "",
     audioPlayer: null,
     audioPlaying: false,
     nlpEntities: []
   }
 },

 onSubmitProfileForm(data) {
 console.log('data')
 console.log(data)
      this.$vbsModal.close();
    },
beforeMount: async function() {
   /*     this.$vbsModal.open({
        content: EnterPasswordComponent,
        size: ModalSize.LARGE,
        staticBackdrop: true,
        contentProps: {
          emailAdress: this.email,
          privateAccount: this.private,
          userName: this.username,
          staticBackdrop: this.staticBackdrop,
          center: this.centered,
        },
        contentEmits: {
          onUpdate: this.onSubmitProfileForm,
        },
        center: true,
        backgroundScrolling: true,
      });
      */

},
methods: {
    playAudio(url) {
     if(url) {
        if (!this.audioPlayer) { 
            this.audioPlayer = new Audio(url);
            this.audioPlayer.addEventListener("ended", this.audioStoppedPlaying, false);
        }
        this.audioPlayer.play();

        this.audioPlaying = true;
     }
    },
    pauseAudio() {
        this.audioPlayer.pause();
        this.audioPlaying = false;
    },
    audioStoppedPlaying() {
        this.audioPlaying = false;
    },
    analyzeText(words) {
        const request = {
          method: "POST",
          mode: "cors",
          cache: "default",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ inputText: words.toString() })
        };
        return fetch('http://localhost:3000/NLP/',request).then(response => response.json())
            .then(data => {
                console.log('finished NLP');
                console.log(data.entities.entities);
                this.nlpEntities = data.entities.entities;
            })
        .catch((error) => {
          console.error('Error:', error);
        });
    }

},
 mounted: async function() {    
      const results = reactive({ data: {}, classData: [] });
      const route = useRoute();
      const resultId = computed(()=> route.params.id) 
  
      console.log(`lifecycle method to get results for: ${resultId.value}`)
      const docRef = doc(db, "class_records", route.params.id);
      
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());

        results.data = docSnap.data()

        // Check password
        if (storageHelper.getItem('user-password') !== results.data.password) {
          this.$router.push('/login?q=' + route.path);
        }

        this.words = results.data.otherWords;
        this.longestWords = results.data.longestWords;
        // The following line is for using Google NLP
        //this.analyzeText(this.words);
        this.manualLevelPrediction = results.data.manualLevelPrediction;
        this.sessionLanguage = results.data.sessionLanguage;
        this.studentName = results.data.studentName;
        this.teacherImage = results.data.teacherImage;
        this.teacherName = results.data.teacherName;
        this.teacherComments = results.data.teacherComments;
        this.otherInfo = results.data.otherInfo;
        
        getDownloadURL(ref(storage, results.data.audioRef)).then(url=>{
            this.audioUrl = url;         
        });

        const confidences = results.data.levelPrediction;
        let class5confidence = 0;
        let class6confidence = 0;
        let courseIds = ['2', '3']; 
        let sortedConfidences = [];

        console.log(results.data.levelPrediction);

        if (!this.manualLevelPrediction) {
          class5confidence = confidences.filter((x)=>{ return x.label == "5"})?.[0].score;
          class6confidence = confidences.filter((x)=>{ return x.label == "6"})?.[0].score;

          sortedConfidences = confidences.filter((x)=>{ return (Number(x.label) > 0) && (Number(x.label) < 5); }).sort((x)=>{ return x.score});
          this.maxConfidenceOf5And6 = Math.round(Math.max(class5confidence, class6confidence) * 100);
          courseIds = [sortedConfidences[0].label, sortedConfidences[1].label];
        }

        if (class5confidence > 0.7 || class6confidence > 0.7) {
          console.log('confidence high')

        }
        else {
          // Only display the top 2
          const classQuery = query(collection(db, "classes"), where("courseId", "in", courseIds));
          const classSnap = await getDocs(classQuery);
          const classes = [];
          classSnap.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
           
            const classData = doc.data();
            classes.push({ ...sortedConfidences.filter((x)=>{ return x.label == classData.courseId})?.[0],
            ...classData });

          });
          this.classes = classes;

        }
       
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }


 },

}
</script>
