var AudioTrackSpeechRecognition = (function () {
    "use strict";
    
    var ctor = function (track, completed, interim, lang) {
        var self = this;
        this.recognizing = false;
        this.finalTranscript = "";

        if (window.SpeechRecognition) {
            
            this.recognition = new window.SpeechRecognition()
            
        }
        else if (window.webkitSpeechRecognition) {
            this.recognition = new window.webkitSpeechRecognition()
            console.log('webkit')
            console.log(this.recognition.start)
        }
        if (this.recognition) {
            this.recognition.continuous = true;
            this.recognition.interimResults = true;
            this.recognition.lang = lang || navigator.language;

            this.recognition.onstart = function() {
                //console.log("staring");
            };

            this.recognition.onend = function () {
                //console.log("ending");
              //  self.start();
            }; 



            this.recognition.onresult = function (event) {
                console.log("onresult:", event);
                var interimTranscript = '';
                for (var i = event.resultIndex; i < event.results.length; ++i) {
                    if (event.results[i].isFinal) {
                        self.finalTranscript += event.results[i][0].transcript;
                        completed(event.results[i][0].transcript,
                            event.results[i][0].confidence,
                            self.trackId, new Date());
                    } else {
                        interimTranscript += event.results[i][0].transcript;
                        interim(interimTranscript,self.trackId, new Date());
                    }
                    
                }
            };
            this.track = track;
            this.trackId = track.id;
        }
    };
    ctor.prototype.start = function () {
        this.recognition.start(this.track);
        this.recognizing = true;
        return this;
    };
    ctor.prototype.stop = function () {
        this.recognition.stop(this.track);
        this.recognizing = false;
        return this;
    };
    return ctor;
})();

module.exports = AudioTrackSpeechRecognition;